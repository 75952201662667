.p-checkbox {
    border-radius: 50%;
    transition: box-shadow $transitionDuration;

    .p-checkbox-input {
        border-radius: 2px;
    }

    .p-checkbox-box {
        border-color: nth($checkboxBorder, 3);
        border-radius: 2px;
        position: relative;
    }

    &.p-highlight {
        .p-checkbox-box {
            .p-checkbox-icon.pi-check:before {
                content: "";
                position: absolute;
                top: 6px;
                left: 1px;
                border-right: 2px solid transparent;
                border-bottom: 2px solid transparent;
                transform: rotate(45deg);
                transform-origin: 0% 100%;
                animation: checkbox-check 125ms 50ms linear forwards;
            }
        }
    }

    &:not(.p-disabled) {
        &:has(.p-checkbox-input:hover) {
            box-shadow: 0 0 1px 10px rgba($overlayColor, 0.04);

            .p-checkbox-box {
                border-color: nth($checkboxBorder, 3);
            }

            &.p-highlight {
                box-shadow: 0 0 1px 10px rgba($checkboxActiveBg, 0.04);
            }
        }

        &:has(.p-checkbox-input:focus-visible) {
            box-shadow: 0 0 1px 10px rgba($overlayColor, 0.12);

            .p-checkbox-box {
                border-color: nth($checkboxBorder, 3);
            }

            &.p-highlight {
                box-shadow: 0 0 1px 10px rgba($checkboxActiveBg, 0.12);

                .p-checkbox-box {
                    border-color: $checkboxActiveBorderColor;
                }
            }
        }
    }

    &.p-variant-filled {
        .p-checkbox-box {
            background-color: $inputBg;
        }

        &:not(.p-disabled) {
            &:has(.p-checkbox-input:hover) {
                background-color: $inputBg;
            }
        }
    }
}

.p-input-filled {
    .p-checkbox {
        .p-checkbox-box {
            background-color: $inputBg;
        }

        &:not(.p-disabled) {
            &:has(.p-checkbox-input:hover) {
                background-color: $inputBg;
            }
        }
    }
}

@keyframes checkbox-check {
    0% {
        width: 0;
        height: 0;
        border-color: $checkboxIconActiveColor;
        transform: translate3d(0, 0, 0) rotate(45deg);
    }
    33% {
        width: 4px;
        height: 0;
        transform: translate3d(0, 0, 0) rotate(45deg);
    }
    100% {
        width: 4px;
        height: 10px;
        border-color: $checkboxIconActiveColor;
        transform: translate3d(0, -10px, 0) rotate(45deg);
    }
}
