.p-togglebutton {
    &:not(.p-disabled) {
        &:has(.p-togglebutton-input:focus-visible) {
            .p-button {
                background: $toggleButtonActiveBg;
                border-color: $toggleButtonActiveBorderColor;
            }

            &.p-highlight {
                .p-button {
                    background: $toggleButtonActiveHoverBg;
                    border-color: $toggleButtonActiveHoverBorderColor;
                }
            }
        }
    }
}