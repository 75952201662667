.p-treeselect {
    .p-treeselect-label, .p-treeselect-trigger {
        background-image: none;
        background: transparent;
    }

    .p-treeselect-label {
        border: 0 none;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor;
    }
}

.p-treeselect-item {
    .p-ink {
        background-color: rgba($primaryColor, .16);
    }
}

.p-input-filled {
    .p-treeselect {
        @include filled-input-wrapper();

        .p-treeselect-label {
            &:hover {
                background-image: none;
                background: transparent;
            }
        }
    }
}

.p-float-label {
    .p-treeselect-label {
        .p-treeselect-token {
            padding: .25rem 1rem;
            margin-top: .25rem;
            margin-bottom: .25rem;
        }
    }
}

.p-input-filled {
    .p-float-label {
        .p-treeselect {
            .p-treeselect-label {
                @include filled-input-float-label();
            }
        }
        
        .p-inputwrapper-filled {
            &.p-treeselect {
                .p-treeselect-label {
                    @include filled-input-float-label();
                }
                
                &.p-treeselect-chip {
                    .p-treeselect-token {
                        padding-top: 0;
                        padding-bottom: 0;
                        margin-top: 0;
                        margin-bottom: 0;
    
                        .p-treeselect-token-icon {
                            font-size: 75%;
                        }
                    }
                }   
            }
        }
    }

    .p-treeselect.p-invalid {
        @include invalid-filled-input-wrapper();
    }
}

.p-treeselect.p-invalid {
    &:not(.p-disabled).p-focus {
        box-shadow: inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor;
    }
}