.p-inputswitch {
    .p-inputswitch-slider:before {
        transition-property: box-shadow transform;
        box-shadow: 0px 3px 1px -2px rgba($overlayColor, 0.2), 0px 2px 2px 0px rgba($overlayColor, 0.14), 0px 1px 5px 0px rgba($overlayColor,.12);
    }

    &:not(.p-disabled) {
        &:has(.p-inputswitch-input:hover) {
            .p-inputswitch-slider:before {
                box-shadow: 0px 3px 1px -2px rgba($overlayColor, 0.2), 0px 2px 2px 0px rgba($overlayColor, 0.14), 0px 1px 5px 0px rgba($overlayColor,.12), 0 0 1px 10px rgba($overlayColor, .04), 
            }

            &.p-highlight {
                .p-inputswitch-slider:before {
                    box-shadow: 0 0 1px 10px rgba($inputSwitchHandleOnBg, .04), 0px 3px 1px -2px rgba($overlayColor, 0.2), 0px 2px 2px 0px rgba($overlayColor, 0.14), 0px 1px 5px 0px rgba($overlayColor,.12);
                }
            }
        }

        &:has(.p-inputswitch-input:focus-visible) {
            .p-inputswitch-slider:before {
                box-shadow: 0 0 1px 10px rgba($overlayColor, .12), 0px 3px 1px -2px rgba($overlayColor, 0.2), 0px 2px 2px 0px rgba($overlayColor, 0.14), 0px 1px 5px 0px rgba($overlayColor,.12);
            }

            &.p-highlight {
                .p-inputswitch-slider:before {
                    box-shadow: 0 0 1px 10px rgba($inputSwitchHandleOnBg, .12), 0px 3px 1px -2px rgba($overlayColor, 0.2), 0px 2px 2px 0px rgba($overlayColor, 0.14), 0px 1px 5px 0px rgba($overlayColor,.12);
                }
            }
        }
    }
}