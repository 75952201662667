@import '../css/variables';
@import '~bootstrap/scss/bootstrap';
@import '../primereact-sass-theme/themes/material/material-light/compact/indigo/theme.scss';

:root {
    // Override css variables of react-toastify
    --toastify-color-info: #{$info};
    --toastify-color-success: #{$success};
    --toastify-color-warning: #{$warning};
    --toastify-color-error: #{$danger};
    --toastify-font-family: #{$font-family-base};
    --toastify-color-progress-light: #{$primary};
    --toastify-color-progress-dark: #{$primary};
    --font-family: #{$font-family-base};
}

a,
.btn-link {
    text-decoration: none;
}

ol,
ul,
dl {
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
}

/* Hide number input arrows */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

/* Remove password eye icon for all browsers */
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button,
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none !important;
}

// Custom Scrollbar
@include above-xl() {
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--bs-dark);

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--bs-dark);
            border-radius: 20px;

            &:hover {
                background-color: var(--bs-primary);
            }
        }
    }
}

//Custom Classes
.mw-1 {
    min-width: 1px;
}

.fs-8 {
    font-size: $theme-size-8 !important;
}

.fs-9 {
    font-size: $theme-size-9 !important;
}

.fs-10 {
    font-size: $theme-size-10 !important;
}

.fs-11 {
    font-size: $theme-size-11 !important;
}

.fs-12 {
    font-size: $theme-size-12 !important;
}

.fs-13 {
    font-size: $theme-size-13 !important;
}

.fs-14 {
    font-size: $theme-size-14 !important;
}

.fs-15 {
    font-size: $theme-size-15 !important;
}

@include custom-class('margin-top', 2);
@include custom-class('margin-top', 3);
@include custom-class('padding-top', 6);
@include custom-class('padding-top', 12);
@include custom-class('font-size', 24);
@include custom-class('min-width', 20);
@include custom-class('width', 20);
@include custom-class('height', 20);
@include custom-class('width', 26);
@include custom-class('height', 26);
@include custom-class('padding-right', 28);
@include custom-class('padding-right', 30);
@include custom-class('width', 32);
@include custom-class('height', 32);
@include custom-class('min-width', 32);
@include custom-class('min-height', 32);
@include custom-class('min-height', 40);
@include custom-class('min-width', 42);
@include custom-class('width', 42);
@include custom-class('height', 42);
@include custom-class('min-height', 42);
@include custom-class('width', 60);
@include custom-class('height', 60);
@include custom-class('min-height', 60);
@include custom-class('min-width', 60);
@include custom-class('height', 70);
@include custom-class('width', 80);
@include custom-class('min-width', 85);
@include custom-class('min-width', 100);
@include custom-class('width', 100);
@include custom-class('min-height', 100);
@include custom-class('width', 110);
@include custom-class('width', 120);
@include custom-class('height', 128);
@include custom-class('width', 130);
@include custom-class('min-width', 130);
@include custom-class('width', 140);
@include custom-class('width', 150);
@include custom-class('min-width', 150);
@include custom-class('max-width', 150);
@include custom-class('max-height', 150);
@include custom-class('width', 160);
@include custom-class('width', 164);
@include custom-class('height', 164);
@include custom-class('max-width', 170);
@include custom-class('width', 170);
@include custom-class('max-width', 180);
@include custom-class('width', 180);
@include custom-class('max-width', 190);
@include custom-class('width', 190);
@include custom-class('max-width', 200);
@include custom-class('width', 200);
@include custom-class('width', 220);
@include custom-class('width', 250);
@include custom-class('width', 280);
@include custom-class('width', 290);
@include custom-class('width', 300);
@include custom-class('height', 300);
@include custom-class('width', 320);
@include custom-class('max-width', 320);
@include custom-class('max-width', 350);
@include custom-class('width', 400);
@include custom-class('max-width', 450);

.theme-loader-z-index {
    z-index: 1002 !important;
}

.custom-auth-banner-panel {
    @include above-md() {
        max-width: 517px;
    }
}

.auth-padding-main {
    padding-top: 86px;

    @include above-md() {
        padding-top: 136px;
    }
}

.auth-padding-inner {
    padding-top: 19px;

    @include above-md() {
        padding-top: 69px;
    }
}

.pageContainer {
    overflow-y: auto !important;

    .pageContent {
        overflow: visible !important;
    }
}

@include maxheight600() {
    .pageContainerList {
        .pageContent {
            .theme-card-cover {
                height: inherit !important;

                >.overflow-auto {
                    height: 300px;
                }
            }
        }
    }
}

@include below-md() {
    .pageContainerList {
        .pageContent {
            .theme-card-cover {
                height: inherit !important;

                >.overflow-auto {
                    height: 300px;
                }
            }
        }
    }
}

.theme-card-header {
    h5 {
        padding-left: 70px;

        .theme-icon-box {
            top: -36px;

            .theme-icon-box-inner {
                width: 35px;
                height: 24px;

                svg {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

.theme-from {
    .theme-common-form {
        height: 100%;
    }
}

.theme-filter-col-2 {
    @include below-md() {
        flex: 1 1 auto;

        .theme-common-form {
            flex: 1 1 auto;
        }
    }

    .btn {
        @include below-sm() {
            flex: 1 1 auto;
        }
    }
}

.theme-from-footer,
.mx-n20 {
    margin-left: -20px;
    margin-right: -20px;
}

.p-20 {
    padding: 20px;
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.theme-modal {
    @include below-1300() {
        .modal-xl {
            --bs-modal-width: 90%;
        }
    }

    @include below-md() {
        --bs-modal-width: 540px;
    }

    .modal-dialog-scrollable {
        .modal-content {
            .theme-common-form {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                max-height: 100%;
            }
        }
    }

    .order-shipping-rate-section {
        &.card {
            box-shadow: none !important;

            .card-body {
                padding: 0;
            }
        }
    }
}

.theme-flip-x {
    transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}

.text-truncate-line-5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 5;
    -webkit-line-clamp: 5;
}

.text-pre-line {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.icon-small {
    svg {
        max-width: 13px;
        max-height: 13px;
    }
}

.icon-small-11 {
    svg {
        max-width: 11px;
        max-height: 11px;
    }
}

.theme-border-dashed {
    border: 1px dashed rgba($dark, .25);
}

.theme-tabs-cover-main {
    .nav-tabs {
        .nav-link {
            --bs-nav-link-padding-y: 0.625rem;

            &.active {
                font-weight: 700;
            }

            &:hover,
            &:focus {
                --bs-nav-link-hover-color: #{$primary};
            }
        }

        .nav-item {
            &.show {
                .nav-link {
                    font-weight: 700;
                }
            }
        }
    }
}

.text-warning-dark {
    color: $warning-dark !important;
}

.bg-warning-light {
    background-color: $warning-light !important;
}

.custom-margin-left-n1 {
    margin-left: -1px !important;
}

.transition-border {
    transition: border-color 0.3s ease-in-out;

    &:hover {
        border-color: $secondary !important;
    }
}

.transition-opacity {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;

    &:hover {
        opacity: .75;
    }
}

.transition-image {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(.9);
    }
}

.cursor-pointer {
    cursor: pointer;
}

.custom-rotate-n15 {
    transform: rotate(-15deg);
}

// Bootstrap Custom CSS
.tooltip {
    position: fixed !important;

    .tooltip-arrow {
        display: none;
    }

    &.tooltip-sm {
        --bs-tooltip-max-width: 200px;
    }
}

.popover {
    box-shadow: var(--bs-popover-box-shadow);

    .popover-arrow {
        display: none;
    }
}

.btn-outline-primary {
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #{rgba($primary, .04)};
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #{rgba($primary, .20)};
}

.btn-outline-dark {
    --bs-btn-hover-color: #{$dark};
    --bs-btn-hover-bg: #{rgba($dark, .10)};
    --bs-btn-hover-border-color: #{$dark};
    --bs-btn-active-color: #{$dark};
    --bs-btn-active-bg: #{rgba($dark, .20)};
}

.btn-outline-secondary {
    --bs-btn-hover-color: #{$secondary};
    --bs-btn-hover-bg: #{rgba($secondary, .10)};
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-active-color: #{$secondary};
    --bs-btn-active-bg: #{rgba($secondary, .20)};
}

.btn-outline-success {
    --bs-btn-hover-color: #{$success};
    --bs-btn-hover-bg: #{rgba($success, .10)};
    --bs-btn-hover-border-color: #{$success};
    --bs-btn-active-color: #{$success};
    --bs-btn-active-bg: #{rgba($success, .20)};
}

.btn-outline-danger {
    --bs-btn-hover-color: #{$danger};
    --bs-btn-hover-bg: #{rgba($danger, .10)};
    --bs-btn-hover-border-color: #{$danger};
    --bs-btn-active-color: #{$danger};
    --bs-btn-active-bg: #{rgba($danger, .20)};
}

.custom-disabled-link {

    .btn:disabled,
    .btn.disabled,
    fieldset:disabled .btn {
        --bs-btn-disabled-color: #{rgba($link-color, .50)};
        --bs-btn-disabled-opacity: #{rgba($link-color, .50)};
    }
}

.offcanvas-backdrop {
    z-index: $zindex-offcanvas;
}

//Prime Custom CSS
.p-inputswitch {
    &.p-highlight {
        .p-inputswitch-slider {
            &:before {
                transform: translateX($inputSwitchHandleWidth + $inputSwitchSliderPadding);
            }
        }
    }
}

.p-inputtext {
    &.p-inputtext-sm {
        font-size: $theme-size-14;
        padding: $input-padding-y-sm $input-padding-x-sm;
    }
}

.p-inputtext-sm {

    .p-inputtext,
    .p-multiselect-label {
        padding: $input-padding-y-sm $input-padding-x-sm;

        &.p-multiselect-filter {
            padding-right: 2rem;
        }
    }

}


.p-multiselect {
    &.p-multiselect-clearable {
        .p-multiselect-clear-icon {
            color: rgba($body-color, 0.6);
            right: 2rem;
        }

        .p-multiselect-label {
            padding-right: 1.25rem;
        }
    }
}

.p-inputtext,
.p-dropdown,
.p-multiselect {
    &.p-disabled {
        background-color: $disabled-color;
        opacity: 1;
        color: rgba($black, .5);

        .p-inputtext {
            color: rgba($black, .5);
        }
    }
}

.p-carousel {
    .p-carousel-indicators {
        margin-top: .5rem;

        .p-carousel-indicator button {
            border-radius: 50%;
        }
    }
}

.p-dropdown,
.p-multiselect {
    .p-icon {
        width: .875rem;
        height: .875rem;
    }
}

.p-accordion {
    .p-accordion-tab {
        box-shadow: $box-shadow-lg;
        margin-bottom: 1rem;
        border-radius: $borderRadius;

        &:not(.p-accordion-tab-active) {
            .p-accordion-header-link {
                &:focus {
                    background: var(--bs-white);
                }
            }
        }

        .p-accordion-tab-active {
            margin-bottom: 0;
        }

        .p-accordion-header {
            .p-accordion-header-link {
                padding: 1rem;
                font-weight: 600;
                font-size: $h5-font-size;
                color: var(--bs-body-color);
                border-radius: $borderRadius;

                .p-accordion-toggle-icon {
                    min-width: 19px;
                    width: 19px;
                    height: 19px;
                }
            }
        }

        .p-accordion-content {
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
            border-top: 1px solid $border-color;
            padding: 1rem;
        }
    }
}

.p-dropdown-panel,
.p-multiselect-panel,
.p-autocomplete-panel {
    max-width: 300px;
}

.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item {
            white-space: normal;
            word-break: break-word;
            height: inherit !important;
        }
    }
}

.p-multiselect-panel {
    .p-multiselect-header {
        .p-multiselect-close {
            display: none;
        }
    }

    .p-multiselect-items {
        .p-multiselect-item {
            white-space: normal;
            word-break: break-word;

            .p-checkbox {
                display: block;
            }
        }
    }
}

.p-autocomplete-panel {
    .p-autocomplete-items {
        .p-autocomplete-item {
            white-space: normal;
            word-break: break-word;
        }
    }
}

.p-component {
    &.p-calendar {
        font-size: 1rem;
    }
}

.table-multi-select-disabled {
    .p-checkbox {
        .p-checkbox-box {
            background-color: var(--bs-white);
            border-color: var(--bs-secondary);

            svg {
                display: none;
            }
        }
    }
}

.p-datatable {
    .p-datatable-tbody {
        >tr {
            &:has(.table-multi-select-disabled) {
                &:not(.p-row-odd) {
                    background: var(--bs-white);
                }
            }

            &:has(.table-multi-select-disabled).p-row-odd.p-highlight {
                background: $tableHeaderCellHoverBg;
            }
        }
    }
}

.p-radio-small {
    .p-radiobutton-box {
        width: 14px;
        height: 14px;
        border-width: 1px;

        .p-radiobutton-icon {
            width: 6px;
            height: 6px;
        }
    }
}

.p-galleria,
.p-galleria-content,
.p-galleria-item-wrapper {
    height: 100%;
    width: 100%;
}

.p-galleria {
    .p-galleria-indicators {
        .p-galleria-indicator {
            &.p-highlight button {
                background: $primary;
            }
        }
    }
}

.p-galleria-mask {
    .p-galleria-item-nav {
        transform: translateY(-50%);
    }
}

@media print {
    @page {
        size: auto;
        margin: 1rem 1rem 1rem 1.5rem;
    }

    .hide-on-print {
        display: none !important;
    }
}

.p-tooltip {
    z-index: 9;

    .p-tooltip-text {
        box-shadow: $box-shadow-sm;
    }
}