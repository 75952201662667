// Bootstrap Variable Override
$enable-negative-margins: true;
$font-family-base: 'Source Sans Pro', sans-serif;
$white: #FFFFFF;
$body-color: #000;
$primary: #005C99;
$secondary: #7F7F7F;
$bg-body-tertiary: #F8F6F4;
$dark: $body-color;
$danger: #DC3545;
$info: #3498db;
$warning: #DE8500;
$success: #198754;
$success-bg-subtle: #D1E7DD;
$danger-bg-subtle: rgba($danger, .20);
$box-shadow: 0 0 4px rgba($body-color, .15);
$box-shadow-lg: 0 2px 2px 0 rgba($body-color, .15);
$box-shadow-sm: 0px 4px 20px 0px rgba($body-color, 0.50);
$tooltip-font-size: 0.75rem;
$tooltip-max-width: 400px;
$tooltip-opacity: 1;
$tooltip-bg: rgba($body-color, .9);
$link-color: #0D6EFD;
$dropdown-font-size: 15px;
$dropdown-padding-y: .75rem;
$navbar-dark-color: $white;
$nav-link-font-size: 15px;
$h2-font-size: 30px;
$h3-font-size: 26px;
$h4-font-size: 22px;
$h5-font-size: 18px;
$btn-font-size: 14px;
$btn-font-weight: 600;
$btn-padding-x: 1.25rem;
$btn-padding-y: 0.594rem;
$border-radius: .25rem;
$zindex-modal-backdrop: 1000;
$zindex-modal: 1000;
$modal-sm: 400px;
$modal-md: 600px;
$modal-lg: 800px;
$modal-xl: 1200px;
$modal-inner-padding: 1rem;
$modal-header-padding-y: .7rem;
$btn-close-color: $body-color;
$btn-close-width: 0.875rem;
$btn-close-opacity: 1;
$btn-close-bg: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z' fill='#{$btn-close-color}'/></svg>");
$border-color: rgba($dark, .25);
$input-border-color: $secondary;
$input-padding-x: 0.75rem;
$input-padding-y: 0.5rem;
$input-border-radius-sm: $border-radius;
$input-border-radius-lg: $border-radius;
$input-padding-y-sm: 0.28125rem;
$input-padding-x-sm: $input-padding-x;
$table-striped-bg: #F5F5F5;
$focus-ring-width: 2px;
$focus-ring-opacity: 1;
$grid-gutter-width: 2rem;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-border-color: $primary;
$nav-link-color: rgba($body-color, .5);
$popover-body-padding-y: .5rem;
$popover-body-padding-x: $popover-body-padding-y;
$popover-border-width: 0;
$popover-box-shadow: $box-shadow-sm;
$popover-border-radius: $border-radius;
$offcanvas-horizontal-width: 900px;
$zindex-offcanvas: 1000;
$offcanvas-border-width: 0;

//Primereact Variable Override
$emphasis-high: $body-color !default;
$emphasis-low: $border-color !default;
$primaryColor: $primary !default;
$accentColor: $body-color !default;
$fontFamily: $font-family-base !default;
$fontSize: 0.875rem !default;
$borderRadius: $border-radius !default;
$inputGroupAddOnMinWidth: 2rem !default;
$inputPadding: $input-padding-y $input-padding-x !default;
$inputListItemPadding: $inputPadding !default;
$inputListHeaderPadding: $inputPadding !default;
$inputSwitchWidth: 1.8125rem !default;
$inputSwitchHeight: 1rem !default;
$inputSwitchBorderRadius: 3rem !default;
$inputSwitchHandleWidth: .5rem !default;
$inputSwitchHandleHeight: .5rem !default;
$inputSwitchSliderPadding: .25rem !default;
$inputSwitchSliderOffBg: $secondary !default;
$inputSwitchHandleOffBg: $white !default;
$inputSwitchSliderOffHoverBg: rgba($secondary, .9) !default;
$inputSwitchSliderOnBg: $success !default;
$inputSwitchSliderOnHoverBg: rgba($success, .9) !default;
$inputSwitchHandleOnBg: $white !default;
$checkboxWidth: 1rem !default;
$checkboxHeight: 1rem !default;
$tableHeaderCellBg: $table-striped-bg !default;
$tableHeaderCellFontWeight: 600 !default;
$tableHeaderCellBorderWidth: 0 !default;
$tableHeaderCellHoverBg: rgba($body-color, 0.05) !default;
$tableBodyRowEvenBg: $tableHeaderCellHoverBg;
$tableBodyRowHoverBg: $tableHeaderCellHoverBg;
$tableHeaderCellHighlightBg: $table-striped-bg !default;
$highlightBg: rgba($body-color, 0.10) !default;
$highlightTextColor: $body-color !default;
$highlightFocusBg: rgba($body-color, 0.10) !default;
$inputListItemHoverBg: rgba($body-color, 0.05) !default;
$inputListItemFocusBg: rgba($body-color, 0.05) !default;
$paginatorElementIconColor: $body-color !default;
$buttonIconOnlyWidth: 2rem !default;
$carouselIndicatorBg: rgba($body-color, 0.25) !default;
$carouselIndicatorHoverBg: rgba($body-color, 0.50) !default;
$carouselIndicatorBorderRadius: 50% !default;
$carouselIndicatorWidth: 0.375rem !default;
$carouselIndicatorHeight: 0.375rem !default;
$inlineSpacing: 0.25rem !default;
$errorColor: $danger !default;
$inputBorder: 1px solid $secondary !default;
$tooltipBg:$white;
$tooltipTextColor:$body-color;

// Custom Variables
$theme-size-8: .5rem;
$theme-size-9: 0.5625rem;
$theme-size-10: 0.625rem;
$theme-size-11: 0.6875rem;
$theme-size-12: 0.75rem;
$theme-size-13: 0.8125rem;
$theme-size-14: 0.875rem;
$theme-size-15: 0.9375rem;
$theme-sidebar-width: 14.375rem;
$theme-sidebar-brand-height: 5em;
$theme-header-height: 4rem;
$warning-dark: #664D03;
$warning-light: #FFF3CD;
$disabled-color: #D9D9D9;

//Responsive Mixins
$below-xsm: 374px;
$below-sm: 575px;
$below-md: 767px;
$below-lg: 991px;
$below-xl: 1199px;
$below-1300: 1299px;
$below-1400: 1399px;
$below-1600: 1600px;
$below-1800: 1800px;

$maxheight600: 600px;
$maxheight400: 400px;

$above-sm: 576px;
$above-md: 768px;
$above-lg: 992px;
$above-xl: 1200px;
$above-1300: 1300px;

@mixin below-xsm {
  @media only screen and (max-width: $below-xsm) {
    @content;
  }
}

@mixin below-sm {
  @media only screen and (max-width: $below-sm) {
    @content;
  }
}

@mixin below-md {
  @media only screen and (max-width: $below-md) {
    @content;
  }
}

@mixin below-lg {
  @media only screen and (max-width: $below-lg) {
    @content;
  }
}

@mixin below-xl {
  @media only screen and (max-width: $below-xl) {
    @content;
  }
}

@mixin below-1300 {
  @media only screen and (max-width: $below-1300) {
    @content;
  }
}

@mixin below-1400 {
  @media only screen and (max-width: $below-1400) {
    @content;
  }
}

@mixin below-1600 {
  @media only screen and (max-width: $below-1600) {
    @content;
  }
}

@mixin below-1800 {
  @media only screen and (max-width: $below-1800) {
    @content;
  }
}

@mixin maxheight600 {
  @media only screen and (max-height: $maxheight600) {
    @content;
  }
}

@mixin maxheight400 {
  @media only screen and (max-height: $maxheight400) {
    @content;
  }
}

@mixin above-sm {
  @media only screen and (min-width: $above-sm) {
    @content;
  }
}

@mixin above-md {
  @media only screen and (min-width: $above-md) {
    @content;
  }
}

@mixin above-lg {
  @media only screen and (min-width: $above-lg) {
    @content;
  }
}

@mixin above-xl {
  @media only screen and (min-width: $above-xl) {
    @content;
  }
}

@mixin above-1300 {
  @media only screen and (min-width: $above-1300) {
    @content;
  }
}

//End of Responsive Mixins

// Generate custom classes for width, min-width, height, min-height
@mixin custom-class($property, $value) {
  .custom-#{$property}-#{$value} {
    #{$property}: #{$value}+px !important;
  }
}